<template>
    <div class="login-box">
        <div class="login-wrap">
            <div class="content">
                <div class="main">
                    <div class="main-left">
                        <el-carousel indicator-position="outside" height="349px" arrow="never">
                            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                            <img :src="item.img" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="main-right">
                        <div class="main-right-logo">
                            <img src="../assets/img/loginIcon/logo.png" alt="">
                        </div>
                        <div>
                            <div class="input">
                                <label class="inputBox">
                                    <div class="input-icon">
                                        <img src="../assets/img/loginIcon/menber-icon.png" alt="">
                                    </div>
                                    <input type="text" placeholder="请输入您的手机号" v-model="password">
                                </label>
                                
                            </div>
                            <div class="input">
                               <label class="inputBox">
                                    <div class="input-icon">
                                        <img src="../assets/img/loginIcon/password-icon.png" alt="">
                                    </div>
                                    <form>
                                        <input :type="typePwd" placeholder="请输入您的新密码" v-model="newpassword" autocomplete="off">
                                    </form>
                                    <div class="forgetIcon">
                                        <img :src="seen?openeye:closeeye" @click="showPwd">
                                    </div>
                                </label>
                            </div>
                            <div class="code">
                                <div class="code-icon">
                                    <img src="../assets/img/loginIcon/code-icon.png" alt="">
                                </div>
                                <input type="text" maxlength="6" placeholder="请输入短信验证码" v-model="code">
                                <button @click="sendCode()">{{isRun?`${runTime}s后重新获取`:'获取验证码'}}</button>
                            </div>
                        </div>
                        <div class="login-button">
                            <button @click="register()">重置密码</button>
                        </div>
                        <div class="login-register">
                            <span>没有账号吗？<a href="/#/Register" class="register">快速注册>></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'register',
    data() {
        return {
            bannerList: [
                {
                    img: require('../../public/img/index/live.png')
                },
                {
                    img: require('../../public/img/index/bank.png')
                },
                {
                    img: require('../../public/img/index/video.png')
                }
            ],
            code: '',
            password: '',
            newpassword: '',
            checked: false,
            typePwd:'password',
            // 倒计时
            isRun: false,
            runTime: 60,
            seen: '', // 图片显示
            openeye: require('../assets/img/loginIcon/password-show.png'),
            closeeye: require('../assets/img/loginIcon/password-hide.png')
        }
    },
    metaInfo: {
        title: '忘记密码-网校', // set a title
        // meta: [
        //     {             // set meta
        //         name: 'keyWords',
        //         content: '我是contact关键字'
        //     },
        //     {
        //         name: 'description',
        //         content: '我是contact描述'
        //     }
        // ]
    },
    methods: {
        showPwd (){
            this.typePwd = this.typePwd === 'password' ? 'text' : 'password';
        },
        clean() {
            this.axios({
                method: 'POST',
                url: 'dev/test/removeMember.html',
                data: {
                    account: '13609780103'
                }
            }).then(() => {
            })
        },
        async sendCode() {
            if(this.isRun) return;
            if(!/^1\d{10}$/.test(this.phone)){
                window.alert("请保证手机号码正确")
                return;
            }
            // await this.checkphone();
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/login/get_sms_code.html',
                data: {
                    phoneNumber: this.phone,
                }
            }).then(() => {
            })
            this.isRun = true;
            this.autoTimer = setInterval(() => {
                if(this.runTime === 0) {
                    this.runTime = 60;
                    this.isRun = false;
                    clearInterval( this.autoTimer);
                    return;
                }
                this.runTime--;
            }, 1000);
        }
    }
}
</script>
<style lang="scss" scoped>
    .login-box {
        .login-wrap {
            padding-top: 92px;
            width: 100%;
            min-width: 1200px;
            height: 672px;
            background: #e9eaed;
            .content {
                padding-top: 48px;
                margin: 0 auto;
                width: 1200px;
                height: 592px;
                .main {
                    margin: 0 auto;
                    width: 913px;
                    display: flex;
                    justify-content: space-between;
                    .main-left {
                        margin-top: 59px;
                        width: 366px;
                        height: 444px;
                        .el-carousel--horizontal {
                            & /deep/ .el-carousel__container {
                                margin-bottom: 40px;
                            }
                            // & /deep/ .el-carousel__indicators {
                            //     & /deep/ .el-carousel__indicator--horizontal {
                                    
                            //     }
                            // }
                        }
                    }
                    .main-right {
                        position: relative;
                        padding-top: 30px;
                        padding-left: 40px;
                        padding-right: 40px;
                        box-sizing: border-box;
                        width: 380px;
                        height: 525px;
                        background: #FFFFFF;
                        border-radius: 6px;
                        .main-right-logo {
                            margin: 0 auto;
                            margin-bottom: 25px;
                            width: 169px;
                            height: 40px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .on {
                            display: none;
                        }
                        .input {
                            margin-bottom: 20px;
                            input {
                                
                                border: none;
                                padding-left: 37px;
                                box-sizing: border-box;
                            }
                            .inputBox {
                                position: relative;
                                display: flex;
                                align-items: center;
                                width: 300px;
                                height: 50px;
                                border: 1px solid #DEDEDE;
                            }
                            .input-icon {
                                position: absolute;
                                top: 17px;
                                left: 12px;
                                width: 16px;
                                height: 16px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .forgetIcon {
                                position: absolute;
                                top: 19px;
                                right: 10px;
                                width: 21px;
                                height: 14px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            
                        }
                        .code {
                            margin-bottom: 20px;
                            display: flex;
                            position: relative;
                            input {
                                padding-left: 37px;
                                box-sizing: border-box;
                                width: 200px;
                                height: 50px;
                                border: 1px solid #DEDEDE;
                            }
                            .code-icon {
                                position: absolute;
                                top: 19px;
                                left: 12px;
                                width: 15px;
                                height: 13px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            button {
                                width: 100px;
                                height: 52px;
                                background: #FF5A74;
                                border-radius: 0px 3px 3px 0px;
                                text-align: center;
                                line-height: 50px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFFFFF;
                                border: none;
                                cursor: pointer;
                            }
                        }
                        .checkConfim {
                            margin-bottom: 28px;
                            display: flex;
                            align-items: center;
                            .checkbox {
                                margin-top: 1px;
                                margin-right: 8px;
                            }
                            p {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                            }
                            a {
                                color: #FA4B66;
                            }
                        }
                        .login-button {
                            button {
                                margin-bottom: 14px;
                                width: 300px;
                                height: 50px;
                                background: #FA4B66;
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                color: #FFFFFF;
                                background-color:#FA4B66;
                                border:none;          
                                border-radius: 30px;
                                cursor: pointer;
                            }
                            .jump {
                                text-align: center;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                a {
                                    color: #FA4B66;
                                }
                            }
                        }
                        .login-register {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 380px;
                            text-align: center;
                            height: 50px;
                            line-height: 50px;
                            background: #F7F7F7;
                            border-radius: 0px 0px 6px 6px;
                            border-top: 1px solid #EEEEEE;
                            span {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                .register {
                                    color: #FA4B66;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
</style>